import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Spinner } from 'react-bootstrap';

function PromptCompetition() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Återställ felmeddelande
    setIsLoading(true);

    // Enkel validering
    if (!name || !email || !prompt) {
      setError('Alla fält måste fyllas i.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://api.bildtavling.teed.se/api/generate', {
        name,
        email,
        prompt,
      });
      setImageUrl(response.data.imageUrl);
      setShowModal(true); // Visa modalen
    } catch (error) {
      setError('Kunde inte generera bilden. Försök igen.');
      console.error('Fel vid bildgenerering:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setName('');
    setEmail('');
    setPrompt('');
    setImageUrl('');
  };

  const handleImageClick = () => {
    setShowImageModal(true);
  };

  const handleImageModalClose = () => {
    setShowImageModal(false);
  };

  const handleInfoModalClose = () => {
    setShowInfoModal(false);
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
      <div className="container">
        <img src="ed-logga.png" alt="Logo" style={{ maxWidth: '200px', marginBottom: '20px' }} />
        <h1 className="mb-4" style={{ fontFamily: 'Roboto, sans-serif' }}>Prompt tävling</h1>
        <form onSubmit={handleSubmit} className="shadow p-4 rounded bg-light" style={{ maxWidth: '500px', margin: '0 auto' }}>
          <div className="mb-3">
            <label className="form-label">Namn:</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">E-postadress:</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Prompt:</label>
            <textarea
              className="form-control"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              required
              rows="3"
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              'Generera bild'
            )}
          </button>
        </form>

        {error && <p className="text-danger mt-3">{error}</p>}

        <p className="mt-3"><a href="#" onClick={() => setShowInfoModal(true)}>Hur fungerar detta?</a></p>

        <Modal show={showModal} onHide={handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Tack för din deltagande!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Dina uppgifter har sparats. Vi kommer att kontakta dig vid behov.</p>
            {imageUrl && (
              <div style={{ textAlign: 'center' }}>
                <h5>Din genererade bild:</h5>
                <img
                  src={imageUrl}
                  alt="Generated"
                  className="img-fluid"
                  style={{ maxWidth: '100%', maxHeight: '400px', objectFit: 'contain', cursor: 'pointer' }}
                  onClick={handleImageClick}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleModalClose}>
              Stäng
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={showImageModal} onHide={handleImageModalClose} size="lg" centered>
          <Modal.Body style={{ padding: 0 }}>
            <img
              src={imageUrl}
              alt="Generated Fullscreen"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleImageModalClose}>
              Stäng
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={showInfoModal} onHide={handleInfoModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Hur fungerar detta? 🤔</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Denna sida använder <strong>DALLE-3</strong> för att generera bilder baserat på din prompt. 🎨✨
              Koden för denna applikation skapades med hjälp av <strong>GPT-4</strong>, en AI-modell från OpenAI. 🤖
              Om du har fler frågor, tveka inte att kontakta oss! 💌
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleInfoModalClose}>
              Stäng
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default PromptCompetition;
